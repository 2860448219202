export interface List {
  name: string
  version: string
}

export interface Filter {
  title: string,
  list: List[]
}
export const filter:Filter[] = [
  {
    title: '开发与运行',
    list: [
      {
        name: 'Python、SQL、Markdown',
        version: '1111'
      },
      {
        name: 'Notebook 交互式编程',
        version: '1111'
      },
      {
        name: '数据可视化',
        version: '1111'
      },
      {
        name: '版本管理',
        version: '1111'
      },
      {
        name: '包管理',
        version: '1111'
      },
      {
        name: '终端',
        version: '1111'
      },
      {
        name: '分布式计算',
        version: '1111'
      },
      {
        name: '断点续跑',
        version: '0111'
      }
    ]
  },
  {
    title: '数据接入和Git集成',
    list: [
      {
        name: '数据库连接',
        version: '1111'
      },
      {
        name: '对象存储挂载',
        version: '1111'
      },
      {
        name: '网络文件存储挂载',
        version: '1111'
      },
      {
        name: '克隆 Git 仓库',
        version: '1111'
      }
    ]
  },
  {
    title: '环境管理',
    list: [
      {
        name: 'Conda',
        version: '1111'
      },
      {
        name: 'CUDA',
        version: '1111'
      }
    ]
  },
  {
    title: '兼容 Open framework',
    list: [
      {
        name: 'PyTorch',
        version: '1111'
      },
      {
        name: 'TensorFlow',
        version: '1111'
      },
      {
        name: 'XGBoost',
        version: '1111'
      }
    ]
  },
  {
    title: '团队协作',
    list: [
      {
        name: '项目文件共享',
        version: '1111'
      },
      {
        name: '数据源共享',
        version: '1111'
      },
      {
        name: 'Notebook分享',
        version: '1111'
      }
    ]
  },
  {
    title: '资源管理、调度',
    list: [
      {
        name: '自动休眠',
        version: '1111'
      },
      {
        name: '任务资源自动伸缩，资源调度优化',
        version: '0111'
      },
      {
        name: '集群指标和日志记录',
        version: '0111'
      },
      {
        name: '资源用量多维度统计报告',
        version: '0111'
      }
    ]
  },
  {
    title: '工作流',
    list: [
      {
        name: '拖拽式创建工作流',
        version: '1111'
      },
      {
        name: '定时运行工作流',
        version: '1111'
      }
    ]
  },
  {
    title: '安全和管理',
    list: [
      {
        name: '管理员控制台',
        version: '1111'
      },
      {
        name: '权限控制',
        version: '1111'
      },
      {
        name: '成员分组',
        version: '0111'
      },
      {
        name: '审计日志',
        version: '0011'
      },
      {
        name: '监控及预警',
        version: '0011'
      },
      {
        name: 'IP 访问控制',
        version: '0011'
      },
      {
        name: 'SSO',
        version: '0001'
      }
    ]
  },
  {
    title: '其他',
    list: [
      {
        name: '企业自有服务器部署',
        version: '0001'
      },
      {
        name: '企业内部系统深度集成',
        version: '0001'
      },
      {
        name: '专属技术支持',
        version: '0001'
      }
    ]
  }
]

// idp 隐私声明
export const IDPrivacyStatement = {
  title: "北京白海科技隐私声明",
  updataTime: "2022年【10】月【31】日",
  data:[
    {
      title: "",
      contentTxt: [
        "本声明仅适用于北京白海科技有限公司（以下简称“白海科技”或“我们”）的北京白海科技算法基础软件平台提供的相关服务（以下简称“本应用”或“平台”或“服务”）。本应用运用自己的操作平台，通过互联网为用户提供算法开发的IDE、计算引擎和数据管理工具。",
        "白海科技深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：合法、正当、必要和诚信原则，目的限定原则，公开透明原则，维护个人信息完整尊严原则，安全保障原则等。同时，白海科技承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。",
        "本声明属于本应用服务使用协议不可分割的一部分，您在同意本应用服务使用协议之时，即视为您已经同意本声明全部内容。请在使用我们的产品或服务前，仔细阅读并了解本声明。",
        "特别提请不满14周岁的未成年人及其监护人的注意，我们已明确不满十四周岁未成年人个人信息保护专门章节，请您仔细阅读。",
        "请注意，本声明不限制我们在法律允许的情况下使用或披露经匿名化处理后的信息。",
      ]
    },
    {
      title: "1.个人信息的收集范围",
      contentTxt: [
        "我们收集您直接提供给平台的个人信息或通过合法途径从第三方收集的个人信息，我们提供的以下服务需要依赖相应信息才得以运行：",
        "（1）用户注册。在您注册本应用帐号时，我们会要求您提供姓名、公司名称、职位、电子邮箱和手机号码等个人信息，以用于注册时的信息验证和后续等功能；",
        "（2）平台服务。在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等信息，以提供和改进本应用的服务；",
        "（3）信息推广及用户联络与改进。我们可能会收集您的个人信息，包括姓名、公司名称、职位、产品使用路径和习惯、电子邮箱、手机号码和咨询问题等，以通过营销或宣传材料以及您可能感兴趣的其他信息向您推广我们的产品或服务，或者通过调研以解答您的咨询问题与改进我们的产品或服务。",
        "您了解并同意，以下信息不适用本声明：",
        "（1）您在使用本应用平台提供的搜索服务时输入的关键字信息；",
        "（2）本应用收集到的您在本应用发布的有关信息，包括但不限于参与活动、成交信息及评价详情；",
        "（3）违反法律规定或违反本应用规则行为及本应用已对您采取的措施。",
      ]
    },
    {
      title: "2.个人信息的使用",
      contentTxt: [
        "（1）共享",
        "我们不会向任何无关第三方提供、出售、出租、共享您的个人信息，除非事先得到您的明确同意，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有信息。本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。",
        "我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。",
        "（2）公开披露：",
        "我们仅会在以下情形下，公开披露您的个人信息：", 
        "a）获得您明确同意后；", 
        "b）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。",
      ]
    },
    {
      title: "3.个人信息的存储和保护",
      contentTxt: [
        "本应用收集的有关您的信息将存储于本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。除法律或相关法规另有约定外，我们将在为您提供服务之目的所必需的期间内保留您的个人信息，但您要求我们立即删除或注销账户的、或法律法规另有规定的除外。",
        "我们采用合理的物理、技术和管理安全措施保护您的个人信息。请妥善保管您的用户名及密码信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。",
        "在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。", 
        "同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。",
      ]
    },
    {
      title: "4.Cookie的使用",
      contentTxt: [
        "（1）在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。该Cookies只能被我们读取，我们的Cookies并不搜集您计算机浏览器或硬盘上的额外信息。",
        "（2）您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。",
        "（3）通过本应用所设cookies所取得的有关信息，将适用本声明。",
      ]
    },
    {
      title: "5.您的权利",
      contentTxt: [
        "按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：" ,
        "（1）访问、更正您的个人信息" ,
        "您有权访问您的个人信息，法律法规规定的例外情况除外。当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。" ,
        "（2）删除您的个人信息" ,
        "在以下情形中，您可以向我们提出删除个人信息的请求：" ,
        "a）如果我们处理个人信息的行为违反法律法规；" ,
        "b）如果我们收集、使用您的个人信息，却未征得您的同意；" ,
        "c）如果我们处理个人信息的行为违反了与您的约定。" ,
        "若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。" ,
        "当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。" ,
        "（3）改变您授权同意的范围" ,
        "每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。" ,
        "（4）个人信息主体注销账户" ,
        "您可注销此前注册的账户。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。" ,
        "（5）个人信息主体获取个人信息副本" ,
        "您有权获取您的个人信息副本。在技术可行的前提下，如数据接口已匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。" ,
        "（6）约束信息系统自动决策" ,
        "在某些功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。" ,
        "（7）响应您的上述请求" ,
        "为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。" ,
        "我们将在合理时间内作出答复。如您不满意，还可以通过本声明第9条列举的方式联系我们。" ,
        "对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。" ,
        "在以下情形中，我们将无法响应您的请求：" ,
        "a）与个人信息控制者履行法律法规规定的义务相关的；",
        "b）与国家安全、国防安全直接相关的；",
        "c）与公共安全、公共卫生、重大公共利益直接相关的；",
        "d）与刑事侦查、起诉、审判和执行判决等直接相关的；",
        "e）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；",
        "f）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；",
        "g）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；",
        "h）涉及商业秘密的。",
      ]
    },
    {
      title: "6．我们如何处理不满十四周岁的未成年人个人信息",
      contentTxt: [
        "如果您是不满14周岁的未成年人，您应要求您的法定监护人仔细阅读本声明，并取得他/他们的明示同意。如果没有法定监护人的明示同意，您不应创建自己的个人信息主体账户。",
        "对于经法定监护人同意而收集不满14周岁的未成年人个人信息的情况，我们只会在受到法律允许、法定监护人明确同意或者保护您所必要的情况下使用或公开披露此信息。 ",
        "如果我们发现在未事先获得可证实的法定监护人同意,的情况下收集了不满14周岁的未成年人个人信息，则会设法尽快删除相关数据。"
      ]
    },
    {
      title: "7.您的个人信息如何在全球范围转移",
      contentTxt: [
        "由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。 ",
        "此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。",
      ]
    },
    {
      title: "8.本声明如何更新",
      contentTxt: [
        "本公司保留随时修改本声明的权利，因此请经常查看。",
        "未经您明确同意，我们不会削减您按照本声明所应享有的权利。我们会在本页面上发布对声明所做的任何变更。" ,
        "对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私声明的具体变更内容）。" ,
        "本声明所指的重大变更包括但不限于：" ,
        "（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；" ,
        "（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；" ,
        "（3）个人信息共享、转让或公开披露的主要对象发生变化；" ,
        "（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；" ,
        "（5）个人信息安全影响评估报告表明存在高风险时。" ,
        "我们还会将本声明的旧版本存档，供您查阅。",
      ]
    },
    {
      title: "9.如何联系我们",
      contentTxt: [
        "如您对本声明或其他相关事宜有疑问，请与我们联系。联系方式：contact@baihai.ai (邮箱）"
      ]
    }
  ]
}